// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$leagues-ui-primary: mat.define-palette(mat.$indigo-palette);
$leagues-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$leagues-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$leagues-ui-theme: mat.define-light-theme(
  (
    color: (
      primary: $leagues-ui-primary,
      accent: $leagues-ui-accent,
      warn: $leagues-ui-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($leagues-ui-theme);

/* You can add global styles to this file, and also import other style files */

@import "./assets/styles/variables.scss";
@import "./assets/styles/global-style.scss";

@font-face {
  font-family: "DavisSans-Bold";
  src: url("#{$font-path}/DavisSans-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DavisSans-BoldItalic";
  src: url("#{$font-path}/DavisSans-BoldItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DavisSans-Italic";
  src: url("#{$font-path}/DavisSans-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DavisSans-Light";
  src: url("#{$font-path}/DavisSans-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DavisSans-LightItalic";
  src: url("#{$font-path}/DavisSans-LightItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DavisSans-Medium";
  src: url("#{$font-path}/DavisSans-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DavisSans-MediumItalic";
  src: url("#{$font-path}/DavisSans-MediumItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DavisSans-Regular";
  src: url("#{$font-path}/DavisSans-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

//--------Stratos font face---------
@font-face {
  font-family: "Stratos-Bold";
  src: url("#{$font-path}/Stratos-Bold.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stratos-BoldItalic";
  src: url("#{$font-path}/Stratos-BoldItalic.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stratos-Italic";
  src: url("#{$font-path}/Stratos-Italic.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stratos-Light";
  src: url("#{$font-path}/Stratos-Light.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stratos-LightItalic";
  src: url("#{$font-path}/Stratos-LightItalic.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stratos-Medium";
  src: url("#{$font-path}/Stratos-Medium.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stratos-MediumItalic";
  src: url("#{$font-path}/Stratos-MediumItalic.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stratos-Regular";
  src: url("#{$font-path}/Stratos-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
//----------------------------------

:root {
  font-size: 100%;
}

html {
  max-height: 100vh;
  overflow: hidden !important;
  top: unset !important;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  overflow: auto;
  position: relative;

  & * {
    font-family: "DavisSans-Regular";
    letter-spacing: unset;
    color: $white-clr;
    margin: 0;
    cursor: default;
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

button,
button *,
a {
  cursor: pointer;
}

a {
  display: block;
  text-decoration: none;
}

p {
  & *:not(b) {
    font-size: inherit !important;
    font-weight: 400;
  }
}

main {
  //width: 1922px;
  background-color: $main-bg-clr;
}

input {
  cursor: text;
}

form {
  width: 100%;
}

.form-valid,
.form-error {
  @include bg-view;

  width: 20px;
  height: 20px;

  span {
    font-size: 13px;
  }
}

.form-valid {
  background-image: url("#{$icon-path}/checked.svg");
}

.form-error {
  background-image: url("#{$icon-path}/form-error-20.svg");
}

.p-0 {
  padding: 0 !important;
}

.fs-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.tick-24-oline-cir {
  background-image: url("#{$icon-path}/Tick-24-OutlineCircle.svg");
}

.add-24-oline-cir {
  background-image: url("#{$icon-path}/Add-24-OutlineCircle.svg");
}

.main-24 {
  background-image: url("#{$icon-path}/Mail-24.svg");
}

.clr-gray-4 {
  color: $gray-4-clr;
}

.clr-yellow {
  color: $title-clr;
}

.clr-danger {
  color: $danger-clr !important;
}

.clr-green {
  color: $green-1-clr !important;
}

.bg-yellow {
  background-color: $title-clr !important;
}

.bg-dark-gray {
  background-color: $dark-gray;
}

.bg-black {
  background-color: $main-bg-clr;
}

.close {
  @include bg-view;

  background-image: url("#{$svg-path}/close.svg");
  position: absolute;
  right: 15px;
  top: 27px;
  z-index: 1;
  width: 20px;
  height: 20px;
}

.frame-search-field {
  .fs-field-wrapper {
    padding-block: 4px !important;
    padding-inline-start: 38px !important;
    padding-inline-end: 8px !important;

    input {
      font-size: 18px !important;
      transform: none !important;
    }
  }
}

.my-events-tbl {
  tbody {
    tr {
      td:nth-child(1) {
        p {
          font-size: 22px;
        }
      }

      td:nth-child(2) {
        p {
          color: $gray-4-clr;
          font-size: 16px;
        }
      }

      td:nth-child(3) {
        p {
          font-size: 20px;
        }
      }

      td:nth-child(4) {
        p {
          font-size: 22px;
        }
      }

      td:nth-child(5) {
        p {
          font-size: 20px;
        }
      }
    }
  }
}

.add-fav-location-venue-card {
  fs-simple-card-footer {
    .fs-simple-card-footer {
      bottom: 140px;
    }
  }
}

.infinite-icon-34 {
  @include bg-view;

  width: 44px;
  height: 20px;
  filter: contrast(0.1);
  background-image: url("#{$image-path}/Infinite.png");
}

.captain-mark {
  @include flex-center;

  font-family: "Stratos-Bold";
  font-size: 22px !important;
  font-weight: 700;
  border: 1px solid $button-brd-clr;
  border-radius: 2px;
  width: 24px;
  height: 24px;
  margin-inline-end: 8px;
}

.fs-league-table-utils {
  @include flex-between;

  width: calc(100% - 114px);
  margin-bottom: 4px;

  & > div {
    @include flex-center;

    span {
      font-size: 16px;
    }

    .info {
      @include bg-view;

      width: 24px;
      height: 24px;
      background-image: url("#{$icon-path}/info-cir-transparent-24-yellow.svg");
    }
  }
}

// .completed {
//   background-image: url("#{$svg-path}/FS-Btn-BG-144-34-Red.svg");
// }

// .inprogress {
//   background-image: url("#{$svg-path}/FS-Btn-BG-144-34-Green.svg");
// }

.event-rounds {
  @include flex-col;
  @include flex-center;

  width: 100%;

  .event-rounds-selector {
    margin-bottom: 20px;

    fs-buttons-group {
      .fs-buttons-group {
        border: 1px solid $button-brd-clr;
        border-radius: 4px;
        padding-top: 2px;

        .fs-buttons-overflow {
          & > button:first-child {
            span {
              color: $danger-clr !important;
              font-size: 22px !important;
            }
          }
        }
      }
    }
  }

  .net-gross-switcher {
    @include flex-end;

    width: 100%;
  }
}

.event-scorecard-table {
  tbody {
    tr {
      td:first-child {
        p {
          span {
            padding-inline-start: 8px;
            color: $gray-4-clr;
          }
        }
      }
    }
  }
}

.regitered-teams-tables {
  .fs-league-table {
    .league-info {
      .team-name {
        h4 {
          color: $green-1-clr !important;
          font-size: 18px;

          a {
            display: block;
            color: inherit;
          }
        }
      }
    }
  }
}

.logout-24-action {
  @include bg-view;

  background-image: url("#{$icon-path}/Logout-24.svg");
  display: block;
  width: 24px;
  height: 24px;
}

.tee-bk {
  background-image: url("#{$icon-path}/Tee-BK.svg");
}

.tee-bl {
  background-image: url("#{$icon-path}/Tee-BL.svg");
}

.tee-ch {
  background-image: url("#{$icon-path}/Tee-CH.svg");
}

.tee-gr {
  background-image: url("#{$icon-path}/Tee-GR.svg");
}

.tee-rd {
  background-image: url("#{$icon-path}/Tee-RD.svg");
}

.tee-wh {
  background-image: url("#{$icon-path}/Tee-WH.svg");
}

.handicap-field {
  width: 86px !important;
  height: 65px !important;
  margin-bottom: 2px !important;

  .fs-form-field {
    width: inherit !important;
    height: inherit !important;

    .fs-field-wrapper {
      padding-inline-start: 20px;

      label {
        top: 7px;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
      }

      input[type="number"] {
        font-family: "Stratos-Bold";
        font-size: 34px;
        text-align: center;
        margin-top: 1px;
        padding-inline-start: 2px;
      }
    }
  }
}

//********************************************************************
//------------Backdrop modal--------------
.background-blur,
.cdk-overlay-dark-backdrop {
  -moz-backdrop-filter: blur(6px) !important;
  -webkit-backdrop-filter: blur(6px) !important;
  -o-backdrop-filter: blur(6px) !important;
  backdrop-filter: blur(6px) !important;
  background-color: #000000a1 !important;
}
//------------------------------------

//-------------Global table cell---------
.cell-rect {
  @include flex-center;

  width: 36px;
  height: 24px;
  border-radius: 3px;
  color: $gray-8-clr;
}

.cell-bg-yellow {
  background-color: $title-clr;
}

.cell-bg-white {
  background-color: $white-clr;
}

.cell-date-clr {
  color: $gray-4-clr;
}

.cell-act-in-progress {
  background-color: $green-1-clr;
  color: $white-clr;
}

.cell-act-reg-bl {
  background-color: $primary-clr;
  color: $white-clr;
}

.cell-act-reg {
  background-color: $title-clr;
  color: $main-bg-clr;
}

.cell-act-res {
  background-color: $danger-clr;
  color: $white-clr;
}

.leave-action {
  @include bg-view;

  width: 32px !important;
  height: 32px !important;
  background-image: url("#{$icon-path}/Logout-32.svg");
  margin-inline-end: 10px;
}
//-------------------------------------------

//--------Sim settings tab options list-------
#tab-sim-settings-container {
  .fs-list-container {
    .fs-list-item {
      height: 48px;
      padding-inline-end: 14px;
    }
  }

  & > div:first-child {
    .fs-buttons-overflow {
      height: 46px;

      button {
        font-size: 28px;
        border: $button-brd-clr 1px solid;
        width: 100px !important;
      }
    }
  }
}
//---------------------------------

//----------Venue page events table-------------
.venue-events-table {
  tr {
    & > td:first-child p {
      font-size: 20px;
    }

    & > td:nth-child(6) p {
      font-size: 24px;
    }
  }
}
//-----------------------------------

//------Players table in my events tab------
.payer-table {
  width: 800px;

  thead {
    tr {
      & > th:nth-child(3) {
        padding-inline-start: 20px;
      }
    }
  }
  tbody {
    tr {
      background-color: $white-clr;
      border-bottom: 1px solid rgba(216, 218, 227, 1) !important;

      td {
        p {
          color: $main-bg-clr;
          font-weight: 700;
        }

        &:nth-child(2) {
          p {
            font-weight: 400;
          }
        }

        // &:nth-child(4) {
        //   p {
        //     color: $danger-clr;
        //   }
        // }
      }

      & > td:nth-child(3) {
        padding-inline-start: 20px;
      }
    }
  }
}
//-------------------------

//-------Registered table---------
.tbl-registered-container {
  width: 573px;

  & > div:first-child {
    .tbl-leadbrd-header {
      .team-info {
        .team-name {
          color: $green-1-clr !important;
        }
      }
    }
  }

  .tbl-leadbrd-header {
    @include flex-center;

    height: 40px;
    width: 100%;
    background-color: $gray-9-clr;
  }

  .tbl-leadbrd-body {
    tbody {
      tr {
        background-color: $gray-10-clr;

        td {
          padding-block: 6px !important;
          border: 1px solid $gray-9-clr;

          &:last-child {
            button[name="Add"] {
              @include bg-view;

              width: 18px;
              height: 18px;
              background-image: url("#{$icon-path}/ADD-24.svg");
            }
          }

          p {
            padding-inline: 8px;
            color: $gray-5-clr;
            font-size: 18px;
          }
        }
      }
    }
  }
}
//--------------------------------
//-----------Round table details-----------------
#round-details {
  .content {
    tbody {
      tr {
        td {
          padding-block: 8px !important;
        }
        & > td:first-child {
          color: $gray-4-clr !important;
        }
      }
    }
  }
}

.fs-stack-list-item-bold {
  .cells-wrapper {
    font-weight: 700 !important;
  }
}

.fs-stack-list-item-deactive {
  .cells-wrapper {
    color: $gray-8-clr !important;
  }
}

.fs-stack-list-cell-2rc {
  @include bg-view;

  background-image: url("#{$svg-path}/Red-2-Circle.svg");
}

.fs-stack-list-cell-rc {
  @include bg-view;

  background-image: url("#{$svg-path}/Red-Circle.svg");
}

.fs-stack-list-cell-2bs {
  @include bg-view;

  background-image: url("#{$svg-path}/Blue-2-Square.svg");
}

.fs-stack-list-cell-bs {
  @include bg-view;

  background-image: url("#{$svg-path}/Blue-Square.svg");
}
//----------------------------------------------
//----------Group button team playe selector-------
.players-per-team-gp-buttons {
  .fs-buttons-gp-wrapper {
    width: 100% !important;
    overflow: hidden !important;

    .fs-buttons-overflow {
      @include flex-start;

      width: inherit !important;
      height: 30px !important;

      button {
        background-color: $gray-7-clr !important;
        margin-inline: 15px !important;
        position: unset !important;
        width: 44px !important;

        span {
          font-weight: 700 !important;
          font-size: 24px !important;
          color: $gray-11-clr !important;
          text-shadow: none !important;
        }
      }

      .active-button {
        background-color: $title-clr !important;
      }
    }
  }
}
//-------------------------------------------------
//----------------Events page-------------
// .event-players-table {
//   @include flex-center;

//   fs-table {
//     width: 400px;
//     margin-top: 22px;

//     thead {
//       tr {
//         background-color: #686868;
//         height: 34px;
//         border-bottom: none !important;

//         th {
//           font-size: 18px !important;
//           padding-inline: 16px;
//           text-transform: unset !important;
//           border: 1px solid #797979;
//         }
//       }
//     }

//     tbody {
//       tr {
//         background-color: $gray-10-clr;

//         td {
//           border: 1px solid #c9cacb;

//           p {
//             font-size: 18px;
//             color: $gray-5-clr;
//             padding-inline: 16px;
//           }

//           .action-btn-default {
//             @include bg-view;

//             width: 24px !important;
//             height: 24px !important;
//           }
//         }
//       }
//     }
//   }
// }

.fs-team-table-header {
  @include flex-between;

  position: relative;
  align-items: stretch;
  padding: 8px 14px;
  padding-inline-end: 8px;
  background-color: $gray-9-clr;
  overflow: hidden;

  .league-info {
    @include flex-start;

    .team-name {
      color: $gray-11-clr;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 700;
    }

    & > * {
      padding-inline-start: 6px;
    }
  }

  .league-actions {
    @include flex-end;

    position: relative;
  }
}

.event-players-table {
  //height: 840px;

  fs-table-new {
    th:nth-child(1) {
      width: 69px;
    }

    th:nth-child(2) {
      width: 220px;
    }

    th:nth-child(3) {
      width: 384px;
    }

    th:nth-child(4) {
      width: 160px;
    }

    th:nth-child(5) {
      width: 110px;
    }

    th:nth-child(6) {
      width: 72px;
    }

    th:nth-child(7) {
      width: 100px;
    }

    th:nth-child(8) {
      width: 44px;
    }
  }
}
//----------------------------------------
//********************************************************************

@media screen and (max-width: $mobile-screen) {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    box-shadow: none !important;
  }
}

//----------------------------------------
//********************************************************************

@media screen and (max-width: $x-extra-large-screen) {
  :root {
    font-size: 100%;
  }
}

@media screen and (max-width: $extra-large-screen) {
  :root {
    font-size: 100%;
  }
}

@media screen and (max-width: $large-screen) {
  :root {
    font-size: 100%;
  }
}

@media screen and (max-width: $medium-screen) {
  :root {
    font-size: 90%;
  }
}

@media screen and (max-width: $small-screen) {
  :root {
    font-size: 80%;
  }
}

@import "./assets/styles/controls.scss";
