@import 'src/assets/styles/variables.scss';
@import 'src/assets/styles/global-style.scss';

.form-inline-controls {
  @include flex-between;

  width: 100%;
}

.fs-control {
  background-color: $main-bg-clr;
  border-radius: 4px;
  transition: 300ms all;
  border: 1px solid $form-field-brd-color;
}

.fs-disabled-filter {
  filter: brightness(0.6) grayscale(1) !important;
}

.fs-el-disabled {
  @extend .fs-disabled-filter;

  cursor: default !important;
  pointer-events: none !important;
}

.fs-el-deactive {
  filter: brightness(0.8) grayscale(1) !important;
}

.fs-input-field-readonly {
  background-color: transparent !important;
  border: none !important;
}

.x-rotate-0 {
  transform: rotateX(0deg);
}

.x-rotate-180 {
  transform: rotateX(180deg);
}

.smooth-modal {
  opacity: 0;
  transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);

  mat-dialog-container {
    overflow: hidden !important;
  }
}

mat-icon {
  font-family: 'Material Icons' !important;
}

.clear-btn {
  min-width: 0px !important;
  padding: 0 !important;
}

mat-form-field {
  display: block;
  margin-bottom: 25px !important;
  width: 100%;
  height: 50px;

  .input-action-btn {
    position: absolute;
    right: -6px;
    top: 13px;
    padding: 0px;
    width: fit-content !important;
    min-width: unset !important;
    transform: scale(0.8);
    z-index: 1;
  }

  .mat-mdc-form-field-infix {
    bottom: 6px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }

  .mat-mdc-text-field-wrapper {
    position: relative;
    background-color: $input-bg !important;
    border-radius: 4px !important;
    transition: 300ms all;
    border: 1px solid $form-field-brd-color !important;

    .mat-mdc-form-field-underline {
      background-color: none !important;
    }

    .mat-mdc-form-field-outline:hover {
      border-bottom: $primary-clr !important;
    }

    label,
    mat-label {
      color: $gray-clr !important;
      font-size: 16px !important;
    }

    input,
    textarea,
    .mat-mdc-select-value-text {
      color: $input-color-txt !important;
    }

    .mdc-line-ripple::before,
    .mdc-line-ripple::after {
      border-bottom: unset !important;
    }
  }

  .mat-mdc-text-field-wrapper:hover {
    border-color: $input-hover-color !important;
  }

  .mat-mdc-text-field-wrapper:focus-within {
    border-color: $primary-clr !important;
  }

  mat-select {
    .mat-mdc-select-arrow {
      color: $white-clr;
    }
  }

  .option-btn {
    position: absolute;
    top: -24px;
    left: -60px;
  }

  .mat-mdc-text-field-wrapper {
    padding-bottom: 0 !important;
    height: inherit;
  }

  .mat-mdc-form-field-flex {
    .mat-mdc-icon-button {
      color: $input-color-label !important;
      padding: 0 !important;
    }

    mat-datepicker-toggle {
      .mat-mdc-icon-button {
        padding-inline-end: 14px !important;
      }
    }
  }
}

.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel {
  background-color: $level3-gray !important;

  mat-option {
    span {
      color: $input-color-txt !important;
      font-size: 18px !important;
    }
  }

  .mat-mdc-optgroup-label span {
    color: $input-color-label !important;
  }
}

.mat-mdc-button-focus-overlay {
  background-color: transparent !important;
}

//------------Button styles--------------
button {
  transition: all 300ms !important;
  border-radius: 4px !important;
  box-sizing: none;
  border: none;
  background-color: transparent;

  mat-icon {
    transition: color 300ms;
  }

  & > span {
    color: inherit !important;
  }
}

button:disabled {
  .mat-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;

    mat-spinner {
      margin-inline-end: 8px;
    }
  }
}

button.card-action-button {
  @include bg-view;

  width: 200px !important;
  height: 50px !important;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  background-image: url('#{$svg-path}/Rectangle 4290.svg') !important;
  cursor: pointer;
}

.fs-button {
  @include flex-center;

  display: flex !important;
  padding-block: 8px;
  min-width: 125px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;

  & > * {
    cursor: inherit;
  }

  .icon {
    @include bg-view;

    width: 18px;
    height: 18px;
    display: block;
    margin-inline-end: 6px;
  }

  span {
    font-weight: 16px;
  }
}

//--------------------------------------

//-----------Active scroll to element----
.fs-visited-el {
  backdrop-filter: contrast(0.8);
}

//-----------Inline popup modalstyles----
.fs-inline-question-dialog {
  @include flex-center;
  @include float-layer;

  z-index: 4;
  left: unset;
  top: -2px;
  right: 0;
  border: 1px solid $primary-clr;
  background-color: $black-1-clr;
  border-radius: 4px;
  width: max-content;
  height: 36px;
  padding-inline: 14px;
  transition: transform $trn-dur-3;

  & > * {
    margin-inline: 4px;
  }

  & > i {
    @include bg-view;

    background-image: url('#{$icon-path}/Danger-24.svg');
    width: 24px;
    height: 24px;
  }

  & > p {
    font-size: 18px;
    color: $white-clr !important;
    text-transform: uppercase;
  }

  & > button {
    border-radius: 4px;
    color: $white-clr;
    width: 58px;
    height: 28px;
    font-size: 16px;
  }

  .no-btn {
    background-color: $primary-clr;
  }

  .yes-btn {
    background-color: $danger-clr;
  }
}

.fs-inline-question-show {
  transform: translateX(0px) !important;
}

//-----------a HTML Tag styles-----------
.link {
  font-size: 16px;
  font-weight: 400;
  color: $link-clr;
}

.mask-link {
  @include float-layer;
  @include full-size;
}

//------------------------------------

//----------Browser scroll styles-------
::-webkit-scrollbar {
  width: 0.8rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: $gray-3-clr;
  width: 8px;
}

//--------------------------------------

h3.title {
  color: $title-clr;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  width: 100%;
}

//------------------FS Cards-------------------
.fs-card {
  .fs-card-footer {
    @include flex-center;

    width: 100%;
    position: absolute;
    bottom: 40px;
  }

  .fs-card-quick-link-container {
    @include flex-evenly;
    @include flex-wrap;

    .fs-card-quick-link {
      margin-bottom: 22px;
    }
  }
}

fs-simple-card {
  fs-simple-card-body {
    .venue-card-label {
      @include float-layer;
      @include flex-start;

      padding: 11px 10px;
      font-family: inherit;

      span {
        font-family: inherit;
        font-weight: 700;
        font-size: 16px;
        color: $title-clr;
        text-transform: uppercase;
      }
    }

    .veneu-events {
      @include flex-center;
      @include flex-col;

      position: absolute;
      bottom: 6px;
      right: 12px;
      z-index: 1;

      span:nth-child(1) {
        color: $primary-clr;
        font-size: 24px;
        font-weight: 700;
        height: 26px;
        @extend .bold-stratos-font;
      }

      span:nth-child(2) {
        font-size: 14px;
        color: $gray-4-clr;
      }
    }
  }
}

fs-m-simple-card {
  fs-m-simple-card-body {
    .venue-card-label {
      @include float-layer;
      @include flex-start;
      padding: 11px 10px;
      font-family: inherit;

      span {
        font-family: inherit;
        font-weight: 700;
        font-size: 16px;
        color: $white-2-clr;
        text-transform: capitalize;
      }
    }

    .venue-events {
      @include flex-center;
      @include flex-col;
      position: absolute;
      bottom: 6px;
      right: 12px;
      z-index: 1;

      span:nth-child(1) {
        color: $primary-clr;
        font-size: 20px;
        font-weight: 700;
        height: 20px;
        @extend .bold-stratos-font;
      }

      span:nth-child(2) {
        font-size: 14px;
        color: $gray-4-clr;
      }
    }
  }
}

.fav-container {
  @include flex-center;

  width: 100%;

  .fav {
    @include bg-view;

    width: 76px;
    height: 25px;
    background-image: url('#{$svg-path}/FAV-Del.svg');
  }
}

//--------------------------------------

//-------------Venue info-------------
.venue-title {
  color: $title-clr !important;
  font-size: 18px !important;
  max-width: 170px;
}

.venue-address {
  color: $white-2-clr !important;
  font-size: 14px !important;
  line-height: 18px !important;
  max-width: 170px;
  text-transform: capitalize;
}

.venue-summ {
  @include flex-between;

  align-items: flex-start;
  width: 100%;

  .venue-profile {
    @include flex-start;

    width: 40%;
    align-items: stretch;

    img {
      width: 124px;
      height: 124px;
      margin-bottom: 10px;
    }

    .venue-name {
      @include flex-start;
      @include flex-col;

      margin-inline-start: 30px;
      align-items: flex-start;

      .venue-title {
        @include text-overflow;
      }

      .venue-title,
      fs-list-selector h4 {
        color: $title-clr !important;
        font-size: 22px !important;
        font-weight: 700;
      }

      .venue-title,
      .description {
        margin-bottom: 4px;
      }

      h3 {
        font-weight: 700;
        font-size: 24px;
        width: inherit;
        color: $white-clr;
        text-transform: uppercase;
      }

      h6 {
        font-weight: 400;
        font-size: 24px;
        width: inherit;
        color: $title-clr;
      }

      .description {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        width: inherit;
      }
    }
  }

  .event-info {
    @include flex-end;
    @include flex-col;

    width: 50%;
    align-items: flex-end;
    text-transform: uppercase;

    h2 {
      font-weight: 700;
      font-size: 32px;
      color: $white-clr;
      max-width: 100%;

      a {
        word-wrap: break-word;
      }
    }

    p {
      color: $white-clr;
      font-size: 24px;
    }

    // button {
    //   @include bg-view;

    //   margin-top: 4px;
    //   background-image: url("#{$svg-path}/mini-green-button.svg");
    //   width: 100px;
    //   height: 34px;
    //   font-size: 20px;
    //   font-weight: 400;
    // }
  }

  .venue-details {
    max-width: 270px;
    text-align: end;

    h3 {
      color: $gray-7-clr;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 4px;
    }

    .description {
      font-size: 20px;
      line-height: 24.6px;
    }
  }
}

//----------------------------------------

//-------------Popup Modal styles-------
.profile {
  .cdk-overlay-container {
    .cdk-overlay-backdrop {
      background-color: rgba(0, 0, 0, 0.8);
    }

    // @media (max-width: $mobile-screen) {
    //   .cdk-global-overlay-wrapper {
    //     top: -88px;
    //   }
    // }
  }
}

.cdk-overlay-container {
  .mobile-back-drop {
    @media (orientation: landscape) {
      height: 100dvh;
    }
  }

  .cdk-overlay-backdrop {
    background-color: $modal-bg-clr;
  }

  @media (min-width: 650px) {
    overflow: auto;
  }

  mat-dialog-container {
    .mat-mdc-dialog-surface {
      background-color: transparent;
    }
  }
}

//--------------------------------------

.fs-group-button-round {
  .fs-buttons-group {
    border: 1px solid rgba(133, 133, 133, 1);
    border-radius: 20px;

    .fs-buttons-overflow {
      height: 34px !important;
      border-radius: 20px !important;

      .active-button {
        border-radius: 20px !important;
      }

      button:last-child {
        border-start-end-radius: 20px !important;
        border-end-end-radius: 20px !important;
      }

      button:first-child {
        border-start-start-radius: 20px !important;
        border-end-start-radius: 20px !important;
      }

      button {
        height: 100% !important;
      }
    }
  }
}

.events-list-table {
  display: block;

  fs-table-new {
    th:nth-child(2),
    th:nth-child(3),
    th:nth-child(4),
    th:nth-child(5),
    th:nth-child(6),
    th:nth-child(7),
    th:nth-child(8),
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7),
    td:nth-child(8) {
      & > * {
        @include flex-center;
      }
    }

    th:nth-child(1) {
      width: 370px;
    }

    th:nth-child(2) {
      width: 60px;
    }

    th:nth-child(3) {
      width: 110px;
    }

    th:nth-child(4) {
      width: 110px;
    }

    th:nth-child(5) {
      width: 100px;
    }

    th:nth-child(6) {
      width: 80px;
    }

    th:nth-child(7) {
      width: 150px;
    }

    th:nth-child(8) {
      width: 180px;
    }
  }

  .action-button-details {
    & > button {
      margin-inline-end: 8px;
      width: 125px;
      height: 36px;
    }
  }
}

.scorecard-event-table {
  display: block;

  fs-table-new {
    th:nth-child(2),
    th:nth-child(3),
    th:nth-child(4),
    th:nth-child(5),
    th:nth-child(6),
    th:nth-child(7),
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7) {
      & > * {
        @include flex-center;
      }
    }

    th:nth-child(1) {
      width: 360px;
    }

    th:nth-child(2) {
      width: 110px;
    }

    th:nth-child(3) {
      width: 110px;
    }

    th:nth-child(4) {
      width: 100px;
    }

    th:nth-child(5) {
      width: 80px;
    }

    th:nth-child(6) {
      width: 150px;
    }

    th:nth-child(7) {
      width: 150px;
    }
  }
}

.scorecard-holes-table {
  fs-table-new {
    th:nth-child(1) {
      width: 50px;
    }

    th:nth-child(2) {
      width: 100%;
    }
  }
}

.my-events-table {
  fs-table-new {
    th:nth-child(1),
    th:nth-child(2),
    th:nth-child(4),
    th:nth-child(5),
    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(4),
    td:nth-child(5) {
      & > * {
        @include flex-center;
      }
    }

    td:nth-child(6) {
      & > * {
        @include flex-end;
      }
    }

    td:nth-child(1) {
      width: 70px;
    }

    th:nth-child(2) {
      width: 100px;
    }

    th:nth-child(3) {
      width: 400px;
    }

    th:nth-child(4) {
      width: 130px;
    }

    th:nth-child(5) {
      width: 85px;
    }

    th:nth-child(6) {
      width: 250px;
    }
  }

  .action-button-details {
    button {
      margin-inline-end: 8px;
    }

    .rect-button {
      width: 125px;
      height: 36px;
    }
  }
}

.my-teams-table {
  max-height: 760px;

  fs-table-new {
    th:nth-child(1),
    th:nth-child(3),
    th:nth-child(4),
    th:nth-child(5),
    th:nth-child(6),
    th:nth-child(7),
    td:nth-child(1),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7),
    td:nth-child(8) {
      & > * {
        @include flex-center;
      }
    }

    td:nth-child(2) {
      & > * {
        @include flex-start;

        flex-wrap: wrap;

        p {
          max-width: calc(100% - 40px);
        }
      }
    }

    td:nth-child(3) {
      text-align: center;
    }
  }
}

.leaderboard-table {
  .fs-table-wrapper {
    width: 760px;
    padding-inline: 40px;
  }
}

.leaderboard-table-strock {
  fs-table-new {
    th:nth-child(1),
    th:nth-child(2),
    th:nth-child(4),
    th:nth-child(5),
    th:nth-child(6),
    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6) {
      & > * {
        @include flex-center;
      }
    }

    th:nth-child(1) {
      width: 54px;
    }

    th:nth-child(2) {
      width: 70px;
    }

    th:nth-child(3) {
      width: 267px;
    }

    th:nth-child(4) {
      width: 69px;
    }

    th:nth-child(5) {
      width: 60px;
    }

    th:nth-child(6) {
      width: 60px;
    }

    th:nth-child(7) {
      width: 200px;
    }
  }
}

.leaderboard-table-wl {
  fs-table-new {
    th:nth-child(1),
    th:nth-child(2),
    th:nth-child(4),
    th:nth-child(5),
    th:nth-child(6),
    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6) {
      & > * {
        @include flex-center;
      }
    }

    th:nth-child(1) {
      width: 70px;
    }

    th:nth-child(2) {
      width: 70px;
    }

    th:nth-child(3) {
      width: 267px;
    }

    th:nth-child(4) {
      width: 69px;
    }

    th:nth-child(5) {
      width: 60px;
    }

    th:nth-child(6) {
      width: 60px;
    }

    th:nth-child(7) {
      width: 200px;
    }
  }
}

.event-players-table {
  overflow: hidden;

  fs-table-new {
    td {
      padding-block: 0;
    }

    td:nth-child(2) {
      padding-inline: 8px;
    }

    th:nth-child(1) {
      width: 30px;
    }

    th:nth-child(2) {
      width: 340px;
    }

    th:nth-child(3) {
      width: 30px;
    }

    th:nth-child(4) {
      width: 134px;
    }
  }
}

.fs-button-border {
  border: 1px solid $button-brd-clr;

  &.success {
    border-color: $success-clr;
    color: $success-clr;
  }

  &.danger {
    border-color: $danger-clr;
    color: $danger-clr;
  }

  &.warning {
    border-color: $title-clr;
    color: $title-clr;
  }
}

.profile-image-viewer {
  @include flex-center;

  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: $success-clr;
  border: 2px solid $button-brd-clr;
  object-fit: cover;
  object-position: center;

  & > span {
    @extend .bold-stratos-font;
    font-weight: 700;
    font-size: 56px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
}

.fs-thead-isolated {
  @include flex-start;

  margin-bottom: 6px;
  align-items: stretch;
  background-color: #575961;
  height: 34px;
  border-bottom: none;

  & > div {
    @include flex-center;

    text-transform: unset;
    border: 1px solid #797979;
    padding-inline: 12px;

    div {
      @include flex-center;

      p {
        color: $gray-4-clr;
        font-weight: 400;
        font-size: 18px;
        text-align: center;

        & > * {
          color: inherit;
        }
      }
    }
  }
}

.fs-table-ui-2 {
  width: 100%;

  table {
    border-collapse: separate !important;

    thead {
      tr {
        background-color: #686868;
        height: 34px !important;
        border-bottom: none !important;

        th {
          text-transform: unset !important;
          border: 1px solid #797979;
          padding-inline: 12px;

          p {
            color: $gray-4-clr;
            font-weight: 400;
            font-size: 18px;

            & > * {
              color: inherit;
            }
          }
        }
      }
    }

    tbody {
      tr {
        background-color: $gray-10-clr;
        height: 36px !important;

        td {
          border: 1px solid #c9cacb;
          padding-inline: 12px;

          p {
            font-size: 18px;
            color: $gray-5-clr;
            //padding-inline: 12px;

            & > * {
              color: inherit;
            }
          }
        }
      }
    }
  }
}

.fs-table-ui-3 {
  @extend .fs-table-ui-2;

  .header-utils {
    background-color: $gray-9-clr;
    height: 40px;
    width: 100%;
  }
}

//------Players table in my events tab------
.fs-table-ui-4 {
  width: 800px;

  tbody {
    tr {
      background-color: $white-clr;
      border-bottom: 1px solid rgba(216, 218, 227, 1) !important;
      height: 56px !important;

      td {
        p {
          color: $main-bg-clr;
          font-weight: 700;
        }

        &:nth-child(2) {
          p {
            font-weight: 400;
          }
        }
      }
    }
  }
}

//-------------------------

.fs-default-input {
  background-color: transparent;
  border: none;
  box-sizing: unset;
  outline: none;
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  font-weight: inherit;
  width: inherit;
  text-align: center;
  height: 100%;
}

.preferences-tab {
  .fs-buttons-gp-wrapper {
    border: 1px solid $button-brd-clr;
    background-color: $main-bg-clr;
    border-radius: 4px;

    .fs-buttons-overflow {
      height: 36px !important;

      button {
        span {
          font-family: 'DavisSans-Bold';
        }
      }
    }
  }
}

.score-val {
  @include flex-center;

  width: 22px;
  height: 22px;
  color: inherit;
  //border-radius: 2px;
}

.fs-compete-logo {
  @include bg-view;

  width: 380px;
  height: 62px;
  min-height: 62px;
  margin-bottom: 14px;
  background-image: url('#{$svg-path}/FS Compete Logo.svg');
}

.modal-center {
  @include flex-center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.8);

  .modal-container {
    width: calc(84.27dvw - 4px);
    min-height: 198px;
    height: $modal-container-h;
    border: 2px solid $primary-clr;
    border-radius: 8px;
    @extend .regular-font;
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.9);
    overflow: hidden;

    &.h-auto {
      height: auto;
    }

    @media (orientation: landscape) {
      $modal-container-h: calc(100dvh - 144px);
      height: $modal-container-h;

      &.h-auto {
        $modal-container-h: calc(100dvh - 144px);
        height: $modal-container-h;
      }
    }
  }
}

.polygon-btn {
  width: 111px;
  height: 35px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $button-brd-clr;
  border-radius: 4px;
  position: relative;
  clip-path: polygon(
    0 0,
    calc(100% - 12px) 0,
    100% 12px,
    100% 100%,
    12px 100%,
    0 calc(100% - 12px)
  );

  &.bg-danger {
    background-color: $danger-clr;
  }
  &.bg-primary {
    background-color: $primary-clr;
  }
  &::before {
    width: 21px;
    height: 2px;
    background-color: $button-brd-clr;
    position: absolute;
    bottom: 5px;
    left: -6.5px;
    content: '';
    transform: rotate(224deg);
  }
  &::after {
    width: 21px;
    height: 2px;
    background-color: $button-brd-clr;
    position: absolute;
    top: 5px;
    right: -6.5px;
    content: '';
    transform: rotate(225deg);
  }
}

.primary-btn {
  @include flex-center;
  width: calc(100%);
  height: 56px;
  background: $primary-clr;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
