@import 'src/assets/styles/variables.scss';

@mixin d-flex {
  display: flex;
}

@mixin flex-center {
  @include d-flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-end {
  @include d-flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-start {
  @include d-flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-between {
  @include d-flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-around {
  @include d-flex;
  justify-content: space-around;
  align-items: center;
}

@mixin flex-evenly {
  @include d-flex;
  justify-content: space-evenly;
  align-items: center;
}

@mixin flex-wrap {
  flex-wrap: wrap;
}

@mixin flex-col {
  flex-direction: column;
}

@mixin flex-row {
  flex-direction: row;
}

@mixin bg-view {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}

@mixin bg-auto {
  @include bg-view;
  background-size: auto;
}

@mixin bg-contain {
  @include bg-view;
  background-size: contain;
}

@mixin bg-full {
  @include bg-view;
  background-size: 100%;
}

@mixin bg-cover {
  @include bg-view;
  background-size: cover;
}

@mixin full-size {
  width: 100%;
  height: 100%;
}

@mixin float-layer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

@mixin text-overflow {
  @extend .text-overflow;
}

.me-8 {
  margin-inline-end: 8px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-8 {
  margin-block-end: 8px;
}

.mb-16 {
  margin-block-end: 16px;
}

.mb-32 {
  margin-block-end: 32px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mx-8 {
  margin-inline: 8px;
}

.mb-14 {
  margin-bottom: 14px;
}

.mt-8 {
  margin-block-start: 8px;
}

.mt-6 {
  margin-block-start: 6px;
}

.ms-5 {
  margin-left: 5px !important;
}

.ms-10 {
  margin-left: 10px !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row;
}

.pos-rel {
  position: relative;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.full-size {
  width: 100%;
  height: 100%;
}

.full-width {
  width: 100%;
}

.light-font {
  font-family: 'DavisSans-Light';
}

.bold-font {
  font-family: 'DavisSans-Bold';
}

.italic-font {
  font-family: 'DavisSans-Italic';
}

.regular-font {
  font-family: 'DavisSans-Regular';
}

.medium-font {
  font-family: 'DavisSans-Medium';
}

.light-stratos-font {
  font-family: 'Stratos-Light';
}

.bold-stratos-font {
  font-family: 'Stratos-Bold';
}

.italic-stratos-font {
  font-family: 'Stratos-Italic';
}

.regular-stratos-font {
  font-family: 'Stratos-Regular';
}

.medium-stratos-font {
  font-family: 'Stratos-Medium';
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.text-start {
  text-align: start;
}

.ico {
  @include bg-contain;
  display: inline-block;
}

.x10 {
  width: 10px;
  height: 10px;
}

.x18 {
  width: 18px;
  height: 18px;
}

.x24 {
  width: 24px;
  height: 24px;
}

.x28 {
  width: 28px;
  height: 28px;
}

.x32 {
  width: 32px;
  height: 32px;
}

.x36 {
  width: 36px;
  height: 36px;
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-0 {
  transform: rotate(0deg);
}

.overflow-y {
  overflow-y: auto;
  height: 100%;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block !important;
}

.multiline-text-overflow {
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.cursor-pointer {
  cursor: pointer;

  * {
    cursor: inherit;
  }
}

.uppercase {
  text-transform: uppercase;
}

.mb-auto {
  margin-bottom: auto;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

@media (max-width: $mobile-screen), screen and (orientation: landscape) {
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
  }
}
