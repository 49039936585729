//------------Break point screen--------------
$small-screen: 576px;
$mobile-screen: 480px;
$medium-screen: calc(768px + 1px);
$large-screen: 991px;
$extra-large-screen: 1200px;
$x-extra-large-screen: 1400px;

//----------Paths-------------
$image-path: '/assets/images';
$svg-path: '/assets/svgs';
$font-path: '/assets/fonts';
$icon-path: '/assets/icons';

$white-clr: #ffffff;
$white-2-clr: #f2f2f2;
$gray-clr: #bbbcbf;
$gray-2-clr: #575961;
$gray-3-clr: #a5a5a5;
$gray-4-clr: #bdbdbd;
$gray-5-clr: #6a6c74;
$gray-6-clr: #828282;
$gray-7-clr: #a1a4af;
$gray-8-clr: #6a6d77;
$gray-9-clr: #c9cacb;
$gray-10-clr: #e0e0e0;
$gray-11-clr: #3a3c41;
$gray-12-clr: #d8dae3;
$gray-13-clr: #323438;
$gray-14-clr: #858585;
$gray-15-clr: #dfe0e0;
$gray-16-clr: #d0cfcf;
$green-1-clr: #009840;
$blue-1-clr: #4bc3ff;
$black-1-clr: #000000cc;
$main-bg-clr: #000000;
$primary-clr: #3369ff;
$success-clr: #1f883d;
$link-clr: #98a6f9;
$title-clr: #ffc108;
$danger-clr: #e51616;

$dark-gray: #444444;

$button-brd-clr: #858585cc;
$button-bg-clr: #000000b2;
$modal-bg-clr: #000000b2;

$input-bg: #252526;
$input-color-txt: #ffffff;
$form-field-brd-color: #585858;
$input-color-label: #a8a9ac;
$input-hover-color: #838383;
$level3-gray: #29292a;

$grd-gray1-bg: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
  linear-gradient(0deg, rgba(133, 133, 133, 0.8), rgba(133, 133, 133, 0.8));

$trn-dur-1: 100ms;
$trn-dur-2: 200ms;
$trn-dur-3: 300ms;

$modal-container-h: 526px;
$trl-y-start: calc((($modal-container-h - 102dvh) / 2) - $modal-container-h);
$trl-y-end: 0;

//Class variables
/*
  Helps:
    i => Icon
    bg => Background
    ol => Outline
    cr => Circle
*/

//Icons
.i-arrow-up {
  background-image: url('#{$icon-path}/Arrow-Up.svg');
}

.i-arrow-down {
  background-image: url('#{$icon-path}/Arrow-Down.svg');
}

.i-arrow-Left {
  background-image: url('#{$icon-path}/Arrow-Left.svg');
}

.i-arrow-right {
  background-image: url('#{$icon-path}/Arrow-Right.svg');
}

.i-user {
  background-image: url('#{$icon-path}/User-24.svg');
}

.i-person {
  background-image: url('#{$icon-path}/Persons-24.svg');
}

.i-tick-ol-cr {
  background-image: url('#{$icon-path}/Tick-24-OutlineCircle.svg');
}

.i-add-ol-cr {
  background-image: url('#{$icon-path}/Add-24-OutlineCircle.svg');
}

.i-mail {
  background-image: url('#{$icon-path}/Email-24.svg');
}

.i-phone {
  background-image: url('#{$icon-path}/Phone.svg');
}

.i-infinite {
  background-image: url('#{$icon-path}/Infinite.svg');
}

.i-logout {
  background-image: url('#{$icon-path}/Logout-24.svg');
}

.i-logout-32 {
  background-image: url('#{$icon-path}/Logout-32.svg');
}

.i-staff {
  background-image: url('#{$icon-path}/Staff-24.svg');
}

.i-super-user-blue {
  background-image: url('#{$icon-path}/Super-User-BL-28.svg');
}

.i-super-user-yellow {
  background-image: url('#{$icon-path}/Super-User-YL-28.svg');
}

.i-edit {
  background-image: url('#{$icon-path}/Edit-18.svg');
}

.i-search {
  background-image: url('#{$icon-path}/Search-24.svg');
}

.i-calendar {
  background-image: url('#{$icon-path}/Calendar.svg');
}

.i-trash-gray {
  background-image: url('#{$icon-path}/Trash-24-Gray.svg');
}

.i-lock-on-yellow {
  //background-image: url("#{$icon-path}/Lock-ON.svg");
  background-image: url('#{$icon-path}/Lock-ON-Yellow.svg');
}

.i-lock-off-gray {
  //background-image: url("#{$icon-path}/Lock-OFF.svg");
  background-image: url('#{$icon-path}/Lock-OFF-Gray.svg');
}

.i-info-24-yellow-cr {
  background-image: url('#{$icon-path}/info-cir-transparent-24-yellow.svg');
}

.i-db-36 {
  background-image: url('#{$icon-path}/Data-Folder-36.svg');
}

.i-search-36 {
  background-image: url('#{$icon-path}/Search-36.svg');
}

.i-m-db-36 {
  background-image: url('#{$icon-path}/m-Data-Folder-36.svg');
}

.i-m-search-36 {
  background-image: url('#{$icon-path}/m-Search-36.svg');
}

.i-settings-36 {
  background-image: url('#{$icon-path}/Settings-36.svg');
}

.i-company-36 {
  background-image: url('#{$icon-path}/Company-36.svg');
}

.i-trophy-36 {
  background-image: url('#{$icon-path}/Trophy-36.svg');
}

.i-tee-black {
  background-image: url('#{$icon-path}/Tee-BK.svg');
}

.i-tee-blue {
  background-image: url('#{$icon-path}/Tee-BL.svg');
}

.i-tee-chocolate {
  background-image: url('#{$icon-path}/Tee-CH.svg');
}

.i-tee-green {
  background-image: url('#{$icon-path}/Tee-GR.svg');
}

.i-tee-red {
  background-image: url('#{$icon-path}/Tee-RD.svg');
}

.i-tee-white {
  background-image: url('#{$icon-path}/Tee-WH.svg');
}

//----------------------------------------------------------

//Backgrounds
.bg-rect-red {
  background-image: url('#{$svg-path}/FS-Btn-BG-144-34-Red.svg');
}

.bg-rect-green {
  background-image: url('#{$svg-path}/FS-Btn-BG-144-34-Green.svg');
}

.bg-rect-blue {
  background-image: url('#{$svg-path}/FS-Btn-BG-144-34-Blue.svg');
}

.bg-active-round {
  background-image: url('#{$svg-path}/Round-Active-BG.svg');
}

.bg-deactive-round {
  background-image: url('#{$svg-path}/Round-Deactive-BG.svg');
}

.main-mobile {
  background: #00000080;
  background-blend-mode: multiply;
}

.main-background-1 {
  background-image: url('#{$image-path}/FSC-Bkgd-2 1.png');
}

.main-background-2 {
  background-image: url('#{$image-path}/Sawgrass17B 4.png');
}

.bg-rect-blue-40 {
  background-image: url('#{$svg-path}/Rectangle-40.svg');
}

//------------------------------------------------------------

//Background-color
.bg-primary-clr {
  background-color: $primary-clr !important;
  color: $white-clr !important;

  &.outline {
    background-color: transparent !important;
    color: $primary-clr !important;
    border: 1px solid $primary-clr !important;
  }
}

.bg-success-clr {
  background-color: $green-1-clr !important;
  color: $white-clr !important;

  &.outline {
    background-color: transparent !important;
    color: $green-1-clr !important;
    border: 1px solid $green-1-clr !important;
  }
}

.bg-warning-clr {
  background-color: $title-clr !important;
  color: $main-bg-clr !important;

  &.outline {
    background-color: transparent !important;
    color: $title-clr !important;
    border: 1px solid $title-clr !important;
  }
}

.bg-danger-clr {
  background-color: $danger-clr !important;
  color: $white-clr !important;

  &.outline {
    background-color: transparent !important;
    color: $danger-clr !important;
    border: 1px solid $danger-clr !important;
  }
}

.bg-black-clr {
  background-color: $main-bg-clr !important;
  color: $white-clr !important;
}

.bg-dark-gray {
  background-color: $dark-gray !important;
  color: $white-clr !important;
}

.bg-lgray-clr {
  background-color: $gray-14-clr !important;
  color: $white-clr !important;
}

.bg-blue1-clr {
  background-color: $blue-1-clr !important;
  color: $main-bg-clr !important;
}

//Foreground color
//Background-color
.primary-clr {
  color: $primary-clr !important;
}

.success-clr {
  color: $success-clr !important;
}

.warning-clr {
  color: $title-clr !important;
}

.danger-clr {
  color: $danger-clr !important;
}

.black-clr {
  color: $main-bg-clr !important;
}

.gray-4-clr {
  color: $gray-4-clr !important;
}

//Font size
.fx20 {
  font-size: 20px;
}
